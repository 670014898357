.contact-form-section {
    padding: 60px 0;
    background-color: #fff;
    position: relative;
    top: 90px;
    margin-bottom: 90px;
  }
.contact-form-section .common-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.contact-form-section .common-container .contact-form-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #333;
    text-align: center;
    margin-bottom: 2rem;
}
  
  .contact-form {
    max-width: 800px;
    width: 80%;
    margin: 0 auto;
    padding: 40px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .form-row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  @media (max-width: 768px) {
    .form-row {
      flex-direction: column;
      gap: 0;
    }
  }
  
  .form-group {
    flex: 1;
    margin-bottom: 20px;
  }
  
  .full-width {
    width: 100%;
  }
  
  .input-wrapper {
    position: relative;
    margin-bottom: 5px;
  }
  
  .form-input {
    width: 100%;
    padding: 12px 16px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background: #fff;
    transition: all 0.3s ease;
  }
  
  .form-input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.2);
  }
  
  .form-input.error {
    border-color: #dc3545;
  }
  
  .form-label {
    position: absolute;
    left: 16px;
    top: -8px;
    padding: 0 4px;
    background-color: white;
    color: #666;
    font-size: 12px;
    transition: all 0.3s ease;
  }
  
  textarea.form-input {
    min-height: 120px;
    resize: vertical;
  }
  
  .error-message {
    color: #dc3545;
    font-size: 12px;
    margin-top: 4px;
  }
  
  .submit-button {
    width: 100%;
    padding: 14px 20px;
    font-size: 16px;
    font-weight: 600;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  .submit-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .submit-button.loading {
    position: relative;
    color: transparent;
  }
  
  .loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: white;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  /* Focus states for accessibility */
  .form-input:focus + .form-label {
    color: #007bff;
  }
  
  .form-input.error:focus + .form-label {
    color: #dc3545;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .contact-form {
        width: 100%;
      padding: 20px;
    }
  
    .form-input {
      padding: 10px 14px;
    }
  
    .submit-button {
      padding: 12px 16px;
    }
  }

  @media (max-width: 480px) {
    .contact-form-section {
      top: 180px;
      margin-bottom: 180px;
    }
  }