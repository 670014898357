.footer-container {
  background: #0C1807;
  padding: 24px 120px;
}

.footer-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.footer-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-left {
  display: flex;
  align-items: center;
  gap: 80px;
}

.footer-logo img {
  width: 59px;
  height: 59px;
}

.footer-nav {
  display: flex;
  gap: 40px;
}

.nav-link {
  color: white;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
  cursor: pointer;
}

.nav-link:hover {
  color: var(--border-color);
}

.footer-right {
  display: flex;
  align-items: center;
  gap: 64px;
}

.policy-links {
  display: flex;
  gap: 40px;
}

.policy-link {
  color: white;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  line-height: 21px;
  text-decoration: none;
}

.policy-link:hover {
  color: var(--border-color);
}

.social-icons {
  display: flex;
  gap: 24px;
}

.social-icon {
  width: 32px;
  height: 32px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.footer-info {
  display: flex;
  gap: 32px;
  margin-top: 30px;
}

.contact-info {
  display: flex;
  gap: 32px;
}

.info-item {
  display: flex;
  align-items: center;
  gap: 8px;
  color: white;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  line-height: 21px;
}

.info-item img {
  width: 21px;
  height: 21px;
}

.footer-divider {
  height: 1px;
  background: #152A0D;
  width: 100%;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.copyright {
  color: #ADB0AC;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  line-height: 21px;
}

.payment-methods {
  display: flex;
  gap: 32px;
}

.payment-icon {
  height: 32px;
  width: auto;
}

@media (max-width: 1200px) {
  .footer-container {
    padding: 24px 60px;
  }
}
@media (max-width: 1050px) {
  .footer-top {
    flex-direction: column;
  }
}

@media (max-width: 992px) {
  .footer-left {
    gap: 40px;
  }
  
  .footer-nav {
    gap: 20px;
  }
}

@media (max-width: 768px) {
  .footer-container {
    padding: 24px 20px;
  }

  .footer-top {
    flex-direction: column;
    gap: 24px;
  }

  .footer-left {
    flex-direction: column;
  }

  .footer-right {
    flex-direction: column;
    gap: 24px;
  }

  .footer-info {
    flex-direction: column;
  }

  .contact-info {
    flex-direction: column;
    align-items: center;
  }

  .footer-bottom {
    flex-direction: column;
    gap: 16px;
    text-align: center;
  }
}