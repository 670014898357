.pricing-container {
  padding: 60px 0;
}

.pricing-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.pricing-header {
  height: 88px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.pricing-title {
  color: #0F172A;
  font-size: 32px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
}

.pricing-subtitle {
  color: #0F172A;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.pricing-cards {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
}

.pricing-card {
  flex: 1;
  padding: 10px 24px;
  background: white;
  border-radius: 16px;
  border: 1px solid #E2E8F0;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  margin-top: 16px;
}

.pricing-card.featured {
  background: #F0F8F1;
  padding: 10px 24px 80px;
  margin-top: 0;
}

.pricing-card.custom {
  /* width: 384px;
  height: 287px; */
}

.pricing-card.custom .plan-title {
  margin-top: 35px;
}

.trial-badge {
  align-self: end;
  padding: 6px 14px;
  background: rgba(150, 37, 221, 0.15);
  border-radius: 30px;
  color: #0F172A;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  line-height: 21px;
}

.plan-title {
  color: #9725DD;
  font-size: 32px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  line-height: 48px;
  margin-bottom: 15px;
}

.get-started-btn {
  align-self: stretch;
  padding: 14px 40px;
  background: white;
  border: 1px solid #E2E8F0;
  border-radius: 4px;
  color: #0F172A;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;
  margin-bottom: 20px;
}

.get-started-btn.featured {
  background: #45842E;
  color: white;
  border: none;
}

.plan-description {
  color: #0F172A;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  line-height: 25.2px;
}

.features-list-pricing {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 20px;
}

.feature-item-pricing {
  display: flex;
  align-items: center;
  gap: 6px;
}

.check-icon {
  width: 21px;
  height: 21px;
  position: relative;
}

.check-icon img {
  width: 17.5px;
  height: 17.5px;
  position: absolute;
  left: 1.75px;
  top: 1.75px;
  background: #45842E;
}

.divider {
  height: 1px;
  background: #E7EFF1;
  margin-top: 16px;
}

.featured .divider {
  background: #E0ECDD;
}

@media (max-width: 1200px) {
  .pricing-content {
    padding: 0 60px;
  }
}

@media (max-width: 992px) {
  .pricing-cards {
    flex-wrap: wrap;
  }

  .pricing-card {
    flex: 1 1 calc(50% - 12px);
    min-width: 300px;
  }

  .pricing-card.custom {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .pricing-content {
    padding: 0 20px;
  }

  .pricing-card {
    flex: 1 1 100%;
  }
}

@media (max-width: 480px) {
  .pricing-container {
    padding: 40px 0;
  }

  .pricing-title {
    font-size: 24px;
    line-height: 36px;
  }

  .pricing-subtitle {
    font-size: 14px;
    line-height: 20px;
  }
}