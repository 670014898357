.fitness-container {
  padding: 60px 0;
}

.fitness-card {
  position: relative;
  width: 100%;
  height: 209px;
  padding: 40px 60px;
  background: #0C1807;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
}

.fitness-bg-image {
  position: absolute;
  width: 500px;
  height: 343px;
  opacity: 0.3;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  object-fit: cover;
  border: none;
}

.fitness-content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.fitness-text {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.fitness-title {
  color: white;
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  line-height: 36px;
}

.fitness-description {
  width: 620px;
  color: #C2C3C1;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  line-height: 28.8px;
}

.fitness-button {
  display: flex;
  align-items: center;
  gap: 8px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.fitness-button span {
  color: white;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  line-height: 24px;
}

.arrow-icon {
  width: 24px;
  height: 24px;
}

.arrow-icon img {
  width: 17.58px;
  height: 11.15px;
  margin: 6.42px 3.42px;
  background: white;
}

@media (max-width: 1200px) {
  .common-container {
    padding: 0 60px;
  }

  .fitness-description {
    width: 100%;
    max-width: 620px;
  }
}

@media (max-width: 768px) {
  .common-container {
    padding: 0 20px;
  }

  .fitness-card {
    padding: 30px;
  }

  .fitness-bg-image {
    width: 300px;
    height: 206px;
  }
}

@media (max-width: 480px) {
  .fitness-card {
    padding: 20px;
    height: auto;
  }

  .fitness-title {
    font-size: 20px;
    line-height: 30px;
  }

  .fitness-description {
    font-size: 14px;
    line-height: 24px;
  }

  .fitness-bg-image {
    width: 200px;
    height: 137px;
  }
}