@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

.hero-container {
    position: relative;
    /* overflow: hidden; */
  }
  
  .hero-container .common-container {
    position: relative;
    padding: 0;
    width: 100%;
  }
  
  .hero-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

  }
  .hero-slider-container {
    display: flex;
    position: relative;
    width: 100vw;
    height: 100vh;
    max-width: 100%;
  }
  .hero-slider-container .hero-image {
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    object-fit: cover;
  }

  .hero-text-container {
    display: flex;
    flex-direction: column;
    gap: 25px;
    z-index: 1;
    margin: auto;
    padding: 20px;
    max-width: 1000px;
    position: absolute;
  }
  
  .hero-text-content {
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-bottom: 20px;
  }
  
  .hero-title {
    color: var(--white-color);
    font-size: 48px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    line-height: 1.5;
  }
  
  .hero-description {
    color: var(--white-color);
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    line-height: 1.5;
  }
  
  .hero-button {
    display: inline-flex;
    padding: 16px 64px;
    background: var(--white-color);
    border-radius: 4px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }
  .hero-button:hover {
    /* background-color: var(--hover-green-color); */
  }
  .hero-button-text {
    color: var(--dark-green-color);
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
  }

  .masks-container {
    position: absolute;
    bottom: -100px;
    right: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  @media (max-width: 768px) {
    .hero-title {
      font-size: 36px;
    }
  
    .hero-description {
      font-size: 14px;
    }
  
    .hero-button {
      padding: 12px 32px;
    }
  }
  
  @media (max-width: 480px) {
    .hero-text-container {
      left: 0;
      top: 33%;
    }
    .hero-title {
      font-size: 24px;
    }
  
    .hero-description {
      font-size: 10px;
    }
  
    .hero-button {
      padding: 10px 20px;
    }
  }
  