.privacy-container {
  padding: 60px 0;
  background-color: #fff;
  position: relative;
  top: 90px;
  margin-bottom: 90px;
}

.privacy-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
}

.privacy-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #333;
  text-align: center;
  margin-bottom: 2rem;
}

.privacy-section {
  margin-bottom: 2rem;
  word-break: break-word;
}

.privacy-section h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #444;
  margin-bottom: 1rem;
}

.privacy-section p {
  font-size: 1rem;
  line-height: 1.6;
  color: #666;
  margin-bottom: 1rem;
}

.privacy-section ul {
  list-style-type: disc;
  margin-left: 1.5rem;
  margin-top: 0.5rem;
}

.privacy-section li {
  font-size: 1rem;
  line-height: 1.6;
  color: #666;
  margin-bottom: 0.5rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .privacy-title {
    font-size: 2rem;
  }

  .privacy-section h2 {
    font-size: 1.25rem;
  }

  .privacy-content {
    gap: 24px;
  }
}

@media (max-width: 480px) {
  .privacy-container {
    top: 180px;
    margin-bottom: 180px;
  }
}
