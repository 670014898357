.agreement-container {
    padding: 60px 0;
    background-color: #fff;
    position: relative;
    top: 90px;
    margin-bottom: 90px;
  }
  
  .agreement-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    max-width: 800px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .agreement-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #333;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .agreement-section {
    margin-bottom: 2rem;
    word-break: break-word;
  }
  
  .agreement-section h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #444;
    margin-bottom: 1rem;
  }
  
  .agreement-section h3 {
    font-size: 1.25rem;
    font-weight: 500;
    color: #555;
    margin-bottom: 0.75rem;
  }
  
  .last-updated {
    font-style: italic;
    color: #666;
    margin-bottom: 1.5rem;
  }
  
  .agreement-section p {
    font-size: 1rem;
    line-height: 1.6;
    color: #666;
    margin-bottom: 1rem;
  }
  
  .agreement-section ul {
    list-style-type: disc;
    margin-left: 1.5rem;
    margin-top: 0.5rem;
  }
  
  .agreement-section li {
    font-size: 1rem;
    line-height: 1.6;
    color: #666;
    margin-bottom: 0.5rem;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .agreement-title {
      font-size: 2rem;
    }
  
    .agreement-section h2 {
      font-size: 1.25rem;
    }
  
    .agreement-section h3 {
      font-size: 1.1rem;
    }
  
    .agreement-content {
      gap: 24px;
      padding: 0 16px;
    }
  }
  
  @media (max-width: 480px) {
    .agreement-container {
        top: 180px;
        margin-bottom: 180px;
    }
  
    .agreement-title {
      font-size: 1.75rem;
    }
  }