.inAction-container {
  padding: 60px 0;
}

.inAction-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.inAction-header {
  height: 88px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  text-align: center;
}

.inAction-title {
  color: #0F172A;
  font-size: 32px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  line-height: 48px;
  width: 100%;
  text-align: center;
}

.inAction-subtitle {
  color: #0F172A;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  line-height: 24px;
  width: 100%;
  text-align: center;
}

.preview-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.desktop-frame {
  display: flex;
  justify-content: center;
  border-radius: 20px;
  padding: 5px;
}

.desktop-image {
  width: 80% !important;
  border-radius: 15px;
  object-fit: cover;
}

.try-free-button {
  width: 280px;
  padding: 14px 40px;
  background: #45842E;
  border-radius: 4px;
  border: none;
  text-align: center;
  color: white;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.try-free-button:hover {
  background: #3a6f26;
}

@media (max-width: 1200px) {
  .desktop-frame {
    width: 100%;
    height: auto;
  }

  .desktop-image {
    width: calc(100% - 10px);
    height: auto;
  }
}

@media (max-width: 768px) {
  .inAction-title {
    font-size: 28px;
    line-height: 40px;
  }
}

@media (max-width: 480px) {
  .inAction-container {
    padding: 40px 0;
  }

  .inAction-title {
    font-size: 24px;
    line-height: 36px;
  }

  .inAction-subtitle {
    font-size: 14px;
    line-height: 20px;
  }
}