.whyUs-container {
  padding: 60px 0;
 }
 
 .whyUs-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
 }
 
 .whyUs-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  text-align: center;
 }
 
 .whyUs-title {
  color: #0F172A;
  font-size: 32px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  line-height: 48px;
 }
 
 .whyUs-subtitle {
  color: #0F172A;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  line-height: 24px;
 }
 
 .whyUs-cards {
  display: flex;
  gap: 24px;
  width: 100%;
 }
 
 .whyUs-card {
  flex: 1;
  height: 320px;
  padding: 40px 24px 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
 }
 
 .card-icon {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
 }
 
 .icon-image {
  width: 40px;
  height: 40px;
  object-fit: contain;
 }
 
 .card-content {
  height: 122px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  text-align: center;
 }
 
 .card-title {
  color: #0F172A;
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  line-height: 27px;
 }
 
 .card-description {
  color: #475569;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  line-height: 28.8px;
 }
 
 /* Optional: Add hover effect */
 .whyUs-card {
  transition: transform 0.3s ease;
 }
 
 .whyUs-card:hover {
  transform: translateY(-5px);
 }
 
 @media (max-width: 1024px) {
  .whyUs-cards {
    flex-wrap: wrap;
    justify-content: center;
  }
 
  .whyUs-card {
    flex: 1 1 calc(50% - 12px);
    min-width: 280px;
  }
 }
 
 @media (max-width: 768px) {
  .whyUs-title {
    font-size: 28px;
    line-height: 40px;
  }
 
  .whyUs-card {
    flex: 1 1 100%;
  }
 }
 
 @media (max-width: 480px) {
  .whyUs-container {
    padding: 40px 0;
  }
 
  .whyUs-title {
    font-size: 24px;
    line-height: 36px;
  }
 
  .whyUs-subtitle {
    font-size: 14px;
    line-height: 20px;
  }
 
  .card-description {
    font-size: 14px;
    line-height: 24px;
  }
 }