.features-container {
  padding: 60px 0;
}

.features-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.features-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.features-title {
  color: #0F172A;
  font-size: 32px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  line-height: 48px;
}

.features-description {
  color: #0F172A;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  line-height: 28.8px;
}

.features-main {
  display: flex;
  gap: 24px;
  align-items: flex-start;
  width: 100%;
}

.features-image {
  width: 485px;
  border-radius: 8px;
  object-fit: cover;
}

.features-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.feature-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  background: #F0F5F8;
  border-radius: 4px;
}

.feature-number {
  width: 48px;
  padding: 8px;
  background: white;
  border-radius: 2px;
  text-align: center;
  color: #0F172A;
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  line-height: 32.4px;
}

.feature-text {
  flex: 1;
  color: #0F172A;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  line-height: 28.8px;
}

@media (max-width: 1024px) {
  .features-container {
    padding: 80px 0 60px 0;
  }
  
  .features-main {
    flex-direction: column;
    align-items: center;
  }

  .features-image {
    width: 100%;
    max-width: 485px;
  }
}

@media (max-width: 768px) {
  .features-title {
    font-size: 28px;
    line-height: 40px;
  }

  .feature-text {
    font-size: 14px;
    line-height: 24px;
  }
}

@media (max-width: 480px) {

  .features-title {
    font-size: 24px;
    line-height: 36px;
  }

  .features-description {
    font-size: 14px;
    line-height: 24px;
  }
}